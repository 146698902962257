import { useMainStore } from '~/store/main/main.store'
import { getFilters, getProvidersWithTariffs, getTariffs } from '~/graphql/graphql'
import { debounce, omit } from '~/helpers'
import { useCities } from '~/store/cites/cities.store'
import { useTariffs } from '~/store/tariffs/tariffs.store'

export function tariffsWrapperComposible(props: any, emit: any, input?: any, tariffsInput?: any, providers?: boolean) {
  const ctx = useNuxtApp()
  const cityStore = useCities()
  const mainStore = useMainStore()
  const tariffsStore = useTariffs()
  const currentCity = computed(() => cityStore.getCity)
  const $router = useRouter()
  const $route = useRoute()
  const houseUrl = computed(() => tariffsStore.getHouseUrl)
  const getCommonHeaders = computed(() => mainStore.getCommonHeaders)
  const houseHash = computed(() => tariffsStore.getHouseHash)
  const providerClient = computed(() => mainStore.getProviderClient)
  const partner = computed(() => mainStore.getDomainConfig)
  const page = ref(1)
  const pseudoPage = ref(1)
  const temperedFilters = ref({})
  const temperedRanges = ref({})
  const preloadFilters = ref()
  const drawerChips = ref('')
  const showBtnApply = ref(false)
  const mobileFilterDrawer = ref(false)
  const loading = ref(false)
  const loadingMore = ref(false)
  const showDrawer = ref(false)
  const loadingValidate = ref()

  const newTariffs = ref()
  const newProviders = ref()

  const tariffsArr = computed(() => tariffsInfo.value?.data)
  const providersArr = computed(() => providersInfo.value?.providers?.providers)
  const providersInfo = computed(() => newProviders.value || props.providers)
  const tariffsInfo = computed(() => newTariffs.value || props.tariffs)
  const agg = computed(() => tariffsInfo.value?.agg)

  const showDrawerChips = computed({
    get: () => { return !!showDrawer.value },
    set: (val) => {
      showDrawer.value = val
    },
  })

  const locationAgg = computed(() => {
    return agg.value?.locationAgg
  })

  const smartFilters = computed(() => {
    return locationAgg.value?.availableSmartFilters
  })

  const openMobileFilters = () => {
    temperedFilters.value = JSON.parse(JSON.stringify(props.filters))
    temperedRanges.value = JSON.parse(JSON.stringify(props.ranges))
    showBtnApply.value = false
  }

  const currentProvider = (val: any) => {
    return (
      props.providers?.find(el => el.provider?.id === val.provider.id)
      || undefined
    )
  }
  const pSmartFilter = computed(() => /city-internet.*/.test($route.name) ? $route.name?.replace('city-', '') : undefined)
  const currentSmartFilter = computed(() => {
    return smartFilters.value?.find(
      el => el.slug === $route.params.smartFilter || el.slug === pSmartFilter.value || el.slug === props.filters?.smartFilter,
    ) || undefined
  })

  const sortList = ref([
    {
      val: 'POPULAR',
      name: 'Популярные',
      nameShort: 'Популярные',
    },
    {
      val: 'POPULAR_FAST',
      name: 'Быстрые',
      nameShort: 'Быстрые',
    },
    {
      val: 'CHEAP',
      name: 'Подешевле',
      nameShort: 'Цена',
    },
    {
      val: 'EXPENSIVE',
      name: 'Подороже',
      nameShort: 'Цена',
    },
    {
      val: 'SLOW',
      name: 'Мин. скорость',
      nameShort: 'Скорость',
    },
    {
      val: 'FAST',
      name: 'Макс. скорость',
      nameShort: 'Скорость',
    },
  ])
  const currentSort = computed(
    () => sortList.value.find(el => el.val === props.filters?.sort)?.name,
  )
  const setSort = async (val: string) => {
    loading.value = true
    page.value = 1
    pseudoPage.value = 1
    props.filters.sort = val
    const validate = Math.random()
    loadingValidate.value = validate
    hideFilters()
    setQuery()
    const res = providers ? await fetchProviders(0) : await fetchTariffs()
    if (validate === loadingValidate.value) {
      if (providers) newProviders.value = res
      else newTariffs.value = res
      loading.value = false
    }
  }

  const client = ctx._apolloClients.default
  const fetchTariffs = async () => {
    const res = (
      await client.query({
        query: getTariffs,
        variables: {
          input: {
            hash: houseUrl.value && !props.inCity
              ? houseHash.value || undefined
              : undefined,
            url: houseUrl.value && !props.inCity ? houseUrl.value : undefined,
            addrObjFiasId: currentCity.value.fias_id,
            strictTechs: !!houseUrl.value,
            renameNonUniqueTariffs: !partner.value && (!houseUrl.value || !!(input?.hasOwnProperty('url') && !input?.url)),
            lowerProviders: providerClient.value ? [+providerClient.value] : undefined,
            ...input,
          },
          tariffsInput2: {
            houseType: props.filters.houseType || undefined,
            sort: props.filters.sort || 'POPULAR',
            page: page.value,
            pageSize: 20,
            gaming: props.filters.gaming ? props.filters.gaming : undefined,
            promo: props.filters.promo ? props.filters.promo : undefined,
            hasWifi: props.filters.hasWifi ? props.filters.hasWifi : undefined,
            routerIncluded: props.filters.routerIncluded
              ? props.filters.routerIncluded
              : undefined,
            freeConnection: props.filters.freeConnection
              ? props.filters.freeConnection
              : undefined,
            providerIds: props.filters.providers?.length
              ? props.filters.providers
              : undefined,
            techIds: props.filters.tech?.length
              ? props.filters.tech
              : undefined,
            priceMin:
              props.ranges.priceMin && props.filters.priceMin
                ? +props.filters.priceMin
                : undefined,
            priceMax:
              props.ranges.priceMax && props.filters.priceMax
                ? +props.filters.priceMax
                : undefined,
            serviceSets:
              props.filters.serviceSets?.length
              && !(
                props.filters.serviceSets?.length === 1
                && props.filters.serviceSets[0] === '2'
              )
                ? [props.filters.serviceSets]
                : undefined,
            serviceSetsContains:
              props.filters.serviceSets?.length === 1
              && props.filters.serviceSets[0] === '2'
                ? [props.filters.serviceSets]
                : undefined,
            tags:
              props.filters.tags && props.filters.tags.length
                ? props.filters.tags
                : undefined,
            speedMax:
              props.ranges.speedMax && props.filters.speedMax
                ? +props.filters.speedMax
                : undefined,
            speedMin:
              props.ranges.speedMin && props.filters.speedMin
                ? +props.filters.speedMin
                : undefined,
            subscriptionAny: props.filters.subscriptionAny
              ? props.filters.subscriptionAny
              : undefined,
            subscriptionService:
              props.filters.subscriptionServiceIds
              && props.filters.subscriptionServiceIds.length
                ? props.filters.subscriptionServiceIds
                : undefined,
            recommended: props.filters.recommend
              ? props.filters.recommend
              : undefined,
            fastConnection: props.filters.fastConnection
              ? props.filters.fastConnection
              : undefined,
            smartFilter: props.filters.smartFilter
              ? props.filters.smartFilter
              : undefined,
            popularSortParams: {
              debug: !!$route.query.debug || undefined,
            },
            ...tariffsInput,
          },
        },
        context: {
          headers: getCommonHeaders.value,
        },
        fetchPolicy: 'no-cache',
      })
    ).data.location.locationTerms?.tariffs
    if (props.inCity && houseUrl.value) {
      res.data.forEach(el => el.fromAddress = false)
    }
    return res
  }
  const fetchProviders = async (offset) => {
    const res = (
      await client.query({
        query: getProvidersWithTariffs,
        variables: {
          input: {
            hash: houseUrl.value
              ? houseHash.value || undefined
              : undefined,
            url: houseUrl.value ? houseUrl.value : undefined,
            addrObjFiasId: currentCity.value.fias_id,
            strictTechs: !!houseUrl.value,
            renameNonUniqueTariffs: !partner.value && (!houseUrl.value || !!(input?.hasOwnProperty('url') && !input?.url)),
            lowerProviders: providerClient.value ? [+providerClient.value] : undefined,
            ...input,
          },
          tariffsInput2: {
            houseType: props.filters.houseType || undefined,
            sort: props.filters.sort || 'POPULAR',
            page: 1,
            pageSize: 0,
            gaming: props.filters.gaming ? props.filters.gaming : undefined,
            promo: props.filters.promo ? props.filters.promo : undefined,
            hasWifi: props.filters.hasWifi ? props.filters.hasWifi : undefined,
            routerIncluded: props.filters.routerIncluded
              ? props.filters.routerIncluded
              : undefined,
            freeConnection: props.filters.freeConnection
              ? props.filters.freeConnection
              : undefined,
            providerIds: props.filters.providers?.length
              ? props.filters.providers
              : undefined,
            techIds: props.filters.tech?.length
              ? props.filters.tech
              : undefined,
            priceMin:
              props.ranges.priceMin && props.filters.priceMin
                ? +props.filters.priceMin
                : undefined,
            priceMax:
              props.ranges.priceMax && props.filters.priceMax
                ? +props.filters.priceMax
                : undefined,
            serviceSets:
              props.filters.serviceSets?.length
              && !(
                props.filters.serviceSets?.length === 1
                && props.filters.serviceSets[0] === '2'
              )
                ? [props.filters.serviceSets]
                : undefined,
            serviceSetsContains:
              props.filters.serviceSets?.length === 1
              && props.filters.serviceSets[0] === '2'
                ? [props.filters.serviceSets]
                : undefined,
            tags:
              props.filters.tags && props.filters.tags.length
                ? props.filters.tags
                : undefined,
            speedMax:
              props.ranges.speedMax && props.filters.speedMax
                ? +props.filters.speedMax
                : undefined,
            speedMin:
              props.ranges.speedMin && props.filters.speedMin
                ? +props.filters.speedMin
                : undefined,
            subscriptionAny: props.filters.subscriptionAny
              ? props.filters.subscriptionAny
              : undefined,
            subscriptionService:
              props.filters.subscriptionServiceIds
              && props.filters.subscriptionServiceIds.length
                ? props.filters.subscriptionServiceIds
                : undefined,
            recommended: props.filters.recommend
              ? props.filters.recommend
              : undefined,
            fastConnection: props.filters.fastConnection
              ? props.filters.fastConnection
              : undefined,
            smartFilter: props.filters.smartFilter
              ? props.filters.smartFilter
              : undefined,
            popularSortParams: {
              debug: !!$route.query.debug || undefined,
            },
            ...tariffsInput,
          },
          providersInput2: {
            tariffless: !(showClearBtn.value || houseUrl.value),
            bestTariffsCount: 2,
            sort: 'BEST_TARIFF',
            byTariffs: {
              houseType: props.filters.houseType || undefined,
              sort: props.filters.sort || 'POPULAR',
              page: 1,
              pageSize: 0,
              gaming: props.filters.gaming ? props.filters.gaming : undefined,
              promo: props.filters.promo ? props.filters.promo : undefined,
              hasWifi: props.filters.hasWifi ? props.filters.hasWifi : undefined,
              routerIncluded: props.filters.routerIncluded
                ? props.filters.routerIncluded
                : undefined,
              freeConnection: props.filters.freeConnection
                ? props.filters.freeConnection
                : undefined,
              providerIds: props.filters.providers?.length
                ? props.filters.providers
                : undefined,
              techIds: props.filters.tech?.length
                ? props.filters.tech
                : undefined,
              priceMin:
                props.ranges.priceMin && props.filters.priceMin
                  ? +props.filters.priceMin
                  : undefined,
              priceMax:
                props.ranges.priceMax && props.filters.priceMax
                  ? +props.filters.priceMax
                  : undefined,
              serviceSets:
                props.filters.serviceSets?.length
                && !(
                  props.filters.serviceSets?.length === 1
                  && props.filters.serviceSets[0] === '2'
                )
                  ? [props.filters.serviceSets]
                  : undefined,
              serviceSetsContains:
                props.filters.serviceSets?.length === 1
                && props.filters.serviceSets[0] === '2'
                  ? [props.filters.serviceSets]
                  : undefined,
              tags:
                props.filters.tags && props.filters.tags.length
                  ? props.filters.tags
                  : undefined,
              speedMax:
                props.ranges.speedMax && props.filters.speedMax
                  ? +props.filters.speedMax
                  : undefined,
              speedMin:
                props.ranges.speedMin && props.filters.speedMin
                  ? +props.filters.speedMin
                  : undefined,
              subscriptionAny: props.filters.subscriptionAny
                ? props.filters.subscriptionAny
                : undefined,
              subscriptionService:
                props.filters.subscriptionServiceIds
                && props.filters.subscriptionServiceIds.length
                  ? props.filters.subscriptionServiceIds
                  : undefined,
              recommended: props.filters.recommend
                ? props.filters.recommend
                : undefined,
              fastConnection: props.filters.fastConnection
                ? props.filters.fastConnection
                : undefined,
              smartFilter: props.filters.smartFilter
                ? props.filters.smartFilter
                : undefined,
              popularSortParams: {
                debug: !!$route.query.debug || undefined,
              },
              ...tariffsInput,
            },
            offset,
            limit: 50,
          },
        },
        context: {
          headers: getCommonHeaders.value,
        },
        fetchPolicy: 'no-cache',
      })
    ).data.location.locationTerms
    return res
  }

  const filterTariffsDebounce = debounce(async (validate: number) => {
    const res = providers ? await fetchProviders(0) : await fetchTariffs()
    Object.keys(props.ranges).forEach((el: string) => {
      if (
        !props.ranges[el]
        && (!currentSmartFilter.value?.tariffsInput
          || currentSmartFilter.value?.tariffsInput?.[el] !== props.filters[el])
      ) {
        props.filters[el]
          = res?.agg?.[`${el.replace(/M.*/, '')}Range`]?.[
            `m${el.replace(/.*M/, '')}`
          ]
      }
    })
    if (validate === loadingValidate.value) {
      if (providers) newProviders.value = res
      else newTariffs.value = res
      loading.value = false
    }
  }, 300)

  const getPreloadFilters = async () => {
    ['priceMin', 'priceMax', 'speedMin', 'speedMax'].forEach((el: any) => {
      temperedFilters.value[el] = temperedRanges.value[el] ? temperedFilters.value[el]! : 0
    })
    mobileFilterTariffs()

    const res = (
      await client.query({
        query: getFilters,
        variables: {
          input: {
            hash:
              houseUrl.value
                ? houseHash.value || undefined
                : undefined,
            url: houseUrl.value ? houseUrl.value : undefined,
            addrObjFiasId: currentCity.value.fias_id,
            lowerProviders: providerClient.value ? [+providerClient.value] : undefined,
          },
          tariffsInput2: {
            houseType: temperedFilters.value.houseType || undefined,
            sort: temperedFilters.value.sort || 'POPULAR',
            pageSize: 0,
            page: 1,
            techIds: temperedFilters.value.tech?.length
              ? temperedFilters.value.tech
              : undefined,
            gaming: temperedFilters.value.gaming
              ? temperedFilters.value.gaming
              : undefined,
            promo: temperedFilters.value.promo ? temperedFilters.value.promo : undefined,
            hasWifi: temperedFilters.value.hasWifi
              ? temperedFilters.value.hasWifi
              : undefined,
            routerIncluded: temperedFilters.value.routerIncluded
              ? temperedFilters.value.routerIncluded
              : undefined,
            freeConnection: temperedFilters.value.freeConnection
              ? temperedFilters.value.freeConnection
              : undefined,
            providerIds: temperedFilters.value.providers?.length
              ? temperedFilters.value.providers
              : undefined,
            priceMin: temperedFilters.value.priceMin
              ? +temperedFilters.value.priceMin
              : undefined,
            priceMax: temperedFilters.value.priceMax
              ? +temperedFilters.value.priceMax
              : undefined,
            tags:
              temperedFilters.value.tags && temperedFilters.value.tags.length
                ? temperedFilters.value.tags
                : undefined,
            speedMax: temperedFilters.value.speedMax
              ? +temperedFilters.value.speedMax
              : undefined,
            speedMin: temperedFilters.value.speedMin
              ? +temperedFilters.value.speedMin
              : undefined,
            subscriptionAny: temperedFilters.value.subscriptionAny
              ? temperedFilters.value.subscriptionAny
              : undefined,
            subscriptionService:
              temperedFilters.value.subscriptionServiceIds
              && temperedFilters.value.subscriptionServiceIds.length
                ? temperedFilters.value.subscriptionServiceIds
                : undefined,
            subscriptionCategory:
              temperedFilters.value.subscriptionIds
              && temperedFilters.value.subscriptionIds.length
                ? temperedFilters.value.subscriptionIds
                : undefined,
            serviceSets:
              temperedFilters.value.serviceSets?.length
              && !(
                temperedFilters.value.serviceSets?.length === 1
                && temperedFilters.value.serviceSets[0] === '2'
              )
                ? [temperedFilters.value.serviceSets]
                : undefined,
            serviceSetsContains:
              temperedFilters.value.serviceSets?.length === 1
              && temperedFilters.value.serviceSets[0] === '2'
                ? [temperedFilters.value.serviceSets]
                : undefined,
            recommended: temperedFilters.value.recommend
              ? temperedFilters.value.recommend
              : undefined,
            fastConnection: temperedFilters.value.fastConnection
              ? temperedFilters.value.fastConnection
              : undefined,
          },
        },
        context: {
          headers: getCommonHeaders.value,
        },
        fetchPolicy: 'no-cache',
      })
    ).data.location.locationTerms.tariffs

    preloadFilters.value = res;

    ['price-Min', 'price-Max', 'speed-Min', 'speed-Max'].forEach((el: string) => {
      const separated = el.split('-')
      if (temperedFilters.value && !temperedFilters.value[el.replace('-', '')]) {
        temperedFilters.value[el.replace('-', '')]
        = res.agg?.[separated[0] + 'Range']?.[separated[1].toLowerCase()] || 0
      }
    })
  }

  const setQuery = async () => {
    const queryObj = {}
    const omitArr = [
      'priceRange',
      'speedRange',
      'priceMin',
      'priceMax',
      'speedMin',
      'speedMax',
      'sort',
      'category',
    ]
    let goToTariffs
    let clearSF
    let smSlug
    if (currentSmartFilter.value?.tariffsInput) {
      smSlug = currentSmartFilter.value?.slug
      const keysArr = Object.keys(currentSmartFilter.value.tariffsInput)
      if (currentSmartFilter.value.tariffsInput.serviceSets?.[0] || currentSmartFilter.value.tariffsInput.serviceSetsContains?.[0]) {
        if (currentSmartFilter.value.tariffsInput.serviceSets && !(currentSmartFilter.value.tariffsInput.serviceSets?.[0].every(el => props.filters.serviceSets.includes(el + ''))
          && props.filters.serviceSets.every(el => currentSmartFilter.value.tariffsInput.serviceSets?.[0].includes(+el)))) {
          props.filters.smartFilter = ''
          clearSF = true
          goToTariffs = !!$route.params.smartFilter
        }
        else if (currentSmartFilter.value.tariffsInput.serviceSetsContains && !(currentSmartFilter.value.tariffsInput.serviceSetsContains?.[0].every(el => props.filters.serviceSets.includes(el + ''))
          && props.filters.serviceSets.every(el => currentSmartFilter.value.tariffsInput.serviceSetsContains?.[0].includes(+el)))) {
          props.filters.smartFilter = ''
          clearSF = true
          goToTariffs = !!$route.params.smartFilter
        }
        else if (currentSmartFilter.value.tariffsInput.serviceSetsContains) {
          omitArr.push('serviceSets')
        }
      }
      else {
        clearSF = !keysArr.every(
          el =>
            (!currentSmartFilter.value?.tariffsInput?.serviceSets
              && !currentSmartFilter.value?.tariffsInput?.serviceSetsContains
              && currentSmartFilter.value?.tariffsInput?.[el]
              === props.filters?.[el])
              || (currentSmartFilter.value?.tariffsInput?.sort
                && currentSmartFilter.value?.tariffsInput?.[el]
                === props.filters?.[el]),
        )
        goToTariffs = !!$route.params.smartFilter
      }
      if (!clearSF)
        omitArr.push(...Object.keys(currentSmartFilter.value?.tariffsInput))
    }
    props.filters.serviceSetsContains = undefined

    for (const key in omit(props.filters, omitArr)) {
      const keyRename = key.replace('Ids', '')
      if (
        props.filters?.[key]
        && (props.filters[key]?.length
          || (typeof props.filters[key] !== 'object'
            && props.filters[key] !== undefined))
      ) {
        queryObj[keyRename] = Array.isArray(props.filters[key])
          ? props.filters[key].join(',')
          : props.filters[key]
      }
      else {
        queryObj[key] = undefined
      }
    }
    queryObj.sorting = props.filters?.sort
    Object.keys(props.ranges).forEach((el: any) => {
      queryObj[el] = props.ranges[el] ? props.filters[el] : undefined
    })
    if (clearSF) {
      props.filters.smartFilter = ''
      queryObj.smartFilter = undefined
      if (goToTariffs) {
        $router.push({
          path: $route.path.replace(`${smSlug}/`, ''),
          query: {
            ...queryObj,
          },
        })
        return
      }
    }
    if ($route.params.smartFilter === queryObj.smartFilter) queryObj.smartFilter = undefined
    $router.replace({
      path: $route.path,
      query: {
        ...queryObj,
      },
    })
  }

  const filterTariffs = async (city?: boolean) => {
    loading.value = true
    page.value = 1
    pseudoPage.value = 1
    const validate = Math.random()
    loadingValidate.value = validate

    nextTick(() => {
      setQuery()
      filterTariffsDebounce(validate, city)
    })
  }

  const mobileFilterTariffs = async () => {
    showBtnApply.value = true
  }

  const applyMobile = () => {
    loading.value = true
    page.value = 1
    pseudoPage.value = 1
    emit('update:filters', temperedFilters.value)
    hideFilters()
    Object.keys(props.ranges).forEach((el: string) => {
      props.ranges[el] = temperedRanges.value[el]
    })
    const validate = Math.random()
    loadingValidate.value = validate

    nextTick(async () => {
      setQuery()
      const res = providers ? await fetchProviders(0) : await fetchTariffs()
      Object.keys(props.ranges).forEach((el: string) => {
        if (
          !props.ranges[el]
          && (!currentSmartFilter.value?.tariffsInput
            || currentSmartFilter.value?.tariffsInput?.[el] !== props.filters[el])
        ) {
          props.filters[el]
          = res?.agg?.[`${el.replace(/M.*/, '')}Range`]?.[
              `m${el.replace(/.*M/, '')}`
            ]
        }
      })
      if (validate === loadingValidate.value) {
        if (providers) newProviders.value = res
        else newTariffs.value = res
        loading.value = false
      }
    })
  }

  const hideFilters = () => {
    mobileFilterDrawer.value = false
    showDrawerChips.value = false
    preloadFilters.value = undefined
    showBtnApply.value = false
    setTimeout(() => drawerChips.value = '', 200)
    if (window)
      window.document.getElementsByTagName('html')[0].classList.remove('hidden')
  }

  const clearFilters = (val: boolean = true) => {
    const defaultFilter = {
      serviceSets: [],
      providers: [],
      tech: [],
      subscriptionServiceIds: [],
      tags: [],
      sort: 'POPULAR',
      subscriptionAny: undefined,
      priceMin: 0,
      priceMax: 0,
      speedMin: 0,
      speedMax: 0,
      smartFilter: '',
    }
    Object.keys(props.ranges).forEach((el: string) => {
      props.ranges[el] = false
    })
    emit('update:filters', defaultFilter)
    page.value = 1
    pseudoPage.value = 1
    if (val) {
      nextTick(async () => {
        loading.value = true
        const validate = Math.random()
        loadingValidate.value = validate
        setQuery()
        const res = providers ? await fetchProviders(0) : await fetchTariffs()
        if (validate === loadingValidate.value) {
          if (providers) newProviders.value = res
          else newTariffs.value = res
          loading.value = false
        }
      })
    }
  }
  const clearFiltersTempered = () => {
    Object.keys(temperedRanges.value).forEach((el: string) => {
      temperedRanges.value[el] = false
    })
    temperedFilters.value = {
      serviceSets: [],
      providers: [],
      tech: [],
      subscriptionServiceIds: [],
      tags: [],
      sort: 'POPULAR',
      subscriptionAny: undefined,
      priceMin: 0,
      priceMax: 0,
      speedMin: 0,
      speedMax: 0,
      smartFilter: '',
    }
    mobileFilterTariffs()
  }

  const loadMore = async () => {
    if (page.value * (providers ? 10 : 4) > pseudoPage.value) {
      pseudoPage.value += 1
    }
    else if (!loadingMore.value) {
      loadingMore.value = true
      page.value += 1
      const validate = Math.random()
      loadingValidate.value = validate
      const res = providers ? await fetchProviders(providersArr.value?.length) : await fetchTariffs()
      if (validate === loadingValidate.value) {
        if (providers) {
          if (!newProviders.value) {
            newProviders.value = JSON.parse(JSON.stringify(props.providers))
          }
        }
        else if (!newTariffs.value) {
          newTariffs.value = JSON.parse(JSON.stringify(props.tariffs))
        }
        nextTick(() => {
          if (providers) {
            newProviders.value.providers.providers.push(...res.providers.providers)
          }
          else {
            newTariffs.value.data.push(...res.data)
            newTariffs.value.hasNext = res.hasNext
          }
          loadingMore.value = false
          pseudoPage.value += 1
        })
      }
    }
  }

  const showClearBtn = computed(() => {
    const res = props.filters
      ? Object.entries(props.filters).filter(
        el =>
          !!el[1]
          && el[0] !== 'sort'
          && !el[0].includes('Min')
          && !el[0].includes('Max')
          && !el[0].includes('houseType')
          && (Array.isArray(el[1]) ? el[1].length : el[1]),
      ).length
      : 0
    const rangeValid = props.ranges
      ? Object.values(props.ranges).filter(el => !!el).length
      : 0

    return !!res || !!rangeValid
  })
  const showClearTemperedBtn = computed(() => {
    const resTempered = Object.entries(temperedFilters.value).filter(
      el =>
        !!el[1]
        && el[0] !== 'sort'
        && !el[0].includes('Min')
        && !el[0].includes('Max')
        && !el[0].includes('houseType')
        && (Array.isArray(el[1]) ? el[1].length : el[1]),
    ).length
    const rangeValidTempered = Object.values(temperedRanges.value).filter(
      el => !!el,
    ).length

    return !!resTempered || !!rangeValidTempered
  })

  return {
    temperedFilters,
    temperedRanges,
    showBtnApply,
    mobileFilterDrawer,
    loadingMore,
    loading,
    newTariffs,
    tariffsArr,
    providersArr,
    providersInfo,
    tariffsInfo,
    agg,
    pseudoPage,
    locationAgg,
    smartFilters,
    openMobileFilters,
    currentProvider,
    sortList,
    currentSort,
    setSort,
    filterTariffs,
    mobileFilterTariffs,
    applyMobile,
    clearFilters,
    clearFiltersTempered,
    loadMore,
    showClearBtn,
    showClearTemperedBtn,
    hideFilters,
    preloadFilters,
    drawerChips,
    showDrawerChips,
    getPreloadFilters,
  }
}
